export const weekdays = [
  { name: 'Monday', id: 'MO' },
  { name: 'Tuesday', id: 'TU' },
  { name: 'Wednesday', id: 'WE' },
  { name: 'Thursday', id: 'TH' },
  { name: 'Friday', id: 'FR' },
  { name: 'Saturday', id: 'SA' },
  { name: 'Sunday', id: 'SU' },
];

export const MERCHANT_TYPES = [
  { name: 'ISP', id: 'ISP' },
  { name: 'VPC', id: 'VPC' },
  { name: 'MB Authorized', id: 'MB Authorized' },
  { name: 'Reseller', id: 'Reseller' },
];

export const SERVICE_TYPES = [
  { name: 'Aftersales', id: 'Aftersales' },
  { name: 'Sales', id: 'Sales' },
];

export const CONTRACT_TYPES = [
  { name: 'Authorized Agent Agreement', id: 'Authorized Agent Agreement' },
  { name: 'Amendment/Side Letter', id: 'Amendment/Side Letter' },
  { name: 'SBI Agreement', id: 'SBI Agreement' },
  { name: 'Other', id: 'Other' },
];

export const maintenanceService = 'Maintenance';

export const notApplicable = 'N/A';

export const RECORD_PER_PAGE = 25;

export const DEFAULT_OPENING_START_TIME = '08:00';

export const DEFAULT_OPENING_END_TIME = '13:00';

export const DEFAULT_TIMEZONE = 'Europe/Berlin';

export const RESOURCES = {
  merchants: 'merchants',
  outlets: 'outlets',
  addresses: 'addresses',
  deliveryAddresses: 'delivery-addresses',
  marketAreas: 'market-areas',
  experts: 'experts',
  contacts: 'contacts',
  openingHours: 'opening-hours',
  expertTypes: 'expert-types',
  expertPositions: 'expert-positions',
  services: 'services',
  offeredServices: 'offered-services',
  contracts: 'contracts',
  gssnIssues: 'gssn-issues',
};

export enum MarketCode {
  AT = 'at',
  BE = 'be',
  FR = 'fr',
  DE = 'de',
  GR = 'gr',
  IT = 'it',
  NL = 'nl',
  PT = 'pt',
  ES = 'es',
  SE = 'se',
  CH = 'ch',
  UK = 'uk',
}

export const MARKET_TYPES = [
  { name: 'Austria', id: MarketCode.AT },
  { name: 'Belgium', id: MarketCode.BE },
  { name: 'France', id: MarketCode.FR },
  { name: 'Germany', id: MarketCode.DE },
  { name: 'Greece', id: MarketCode.GR },
  { name: 'Italy', id: MarketCode.IT },
  { name: 'Netherlands', id: MarketCode.NL },
  { name: 'Portugal', id: MarketCode.PT },
  { name: 'Spain', id: MarketCode.ES },
  { name: 'Sweden', id: MarketCode.SE },
  { name: 'Switzerland', id: MarketCode.CH },
  { name: 'United Kingdom', id: MarketCode.UK },
];

export const COMMUNICATION_TYPES = [
  { name: 'German', id: 'de' },
  { name: 'Greek', id: 'el' },
  { name: 'French', id: 'fr' },
  { name: 'Italian', id: 'it' },
  { name: 'English', id: 'en' },
  { name: 'Swedish', id: 'sv' },
  { name: 'Spanish', id: 'es' },
  { name: 'Dutch', id: 'nl' },
  { name: 'Portuguese', id: 'pt' },
];
