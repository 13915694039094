/* eslint-disable @typescript-eslint/no-unused-vars */
// core packages
import {
  TextField,
  BooleanField,
  ReferenceField,
  RaRecord,
  downloadCSV,
  SimpleList,
  DateField,
} from 'react-admin';
import jsonExport from 'jsonexport/dist';
import { PaletteMode, useTheme } from '@mui/material';

import { List } from 'components/list/List';
import { Datagrid } from 'components/data-grid/DataGrid';

import { notApplicable } from 'resources/config/resources.config';
import { useSmallScreen } from 'utils/helper';
import { ListActions } from 'components/list/ListActions';
import { OutletFilters } from './OutletFilters';

type OutletDetailsType = {
  [key: string]: any;
};

const OutletExporter = (outlets) => {
  const outletsForExport = outlets.map((outlet: RaRecord) => {
    const outletDetails: OutletDetailsType = {};
    const merchantDetails: OutletDetailsType = {};
    const addressDetails: OutletDetailsType = {};
    const contactDetails: OutletDetailsType = {};

    if (outlet?.id) {
      const {
        id,
        name,
        outletId,
        extOutletId,
        bpId,
        isMainOutlet,
        isActive,
        isApplicant,
        vat,
        brandCode,
        cofico,
      } = outlet;
      Object.assign(outletDetails, {
        id,
        name,
        outletId,
        extOutletId,
        bpId,
        isMainOutlet,
        isActive,
        isApplicant,
        vat,
        brandCode,
        cofico,
      });
    }

    if (outlet?.merchant?.id) {
      const { merchantName, companyId, extCompanyId, type, investorName, vat } =
        outlet.merchant;
      Object.assign(merchantDetails, {
        'merchant.name': merchantName,
        'merchant.companyId': companyId,
        'merchant.extCompanyId': extCompanyId,
        'merchant.type': type,
        'merchant.investorName': investorName,
        'merchant.vat': vat,
      });
    }

    if (outlet?.address?.id) {
      const {
        buildingNumber,
        buildingName,
        street,
        city,
        line1,
        postalCode,
        province,
        provinceCode,
        countryIso2,
        countryIsoNumber,
        countryName,
      } = outlet.address;
      Object.assign(addressDetails, {
        street,
        city,
        line1,
        buildingName,
        buildingNumber,
        postalCode,
        province,
        provinceCode,
        countryIsoNumber,
        countryIso2,
        countryName,
      });
    }

    if (outlet?.contact?.id) {
      const { fax, phone, email } = outlet.contact;
      Object.assign(contactDetails, { email, phone, fax });
    }

    const outletForExport = {
      ...outletDetails,
      ...merchantDetails,
      ...addressDetails,
      ...contactDetails,
    };

    const { bpId, ...outletFields } = outletForExport;
    return outletFields;
  });

  jsonExport(outletsForExport, (err, csv) => {
    downloadCSV(csv, 'outlets');
  });
};

const outletRowStyle = (record: RaRecord, themeMode: PaletteMode) => {
  const colors =
    themeMode === 'light'
      ? {
          default: '#FFF',
          secondary: '#e0e0e075',
        }
      : {
          default: '#222222',
          secondary: '#121212',
        };

  if (!record.extOutletId) {
    return {
      backgroundColor:
        !record.vat || !record.bpId ? colors.secondary : colors.default,
    };
  }

  return { backgroundColor: !record.vat ? colors.secondary : colors.default };
};

export const OutletsList = () => {
  const isSmallScreen = useSmallScreen();
  const themeMode = useTheme().palette.mode;
  return (
    <List
      title="Outlets"
      filters={OutletFilters}
      exporter={OutletExporter}
      actions={<ListActions showExport showFilter showXlsxExport />}
    >
      {isSmallScreen ? (
        <SimpleList
          primaryText={
            <ReferenceField
              source="merchantId"
              reference="merchants"
              label="Legal Name"
              sortable={false}
            >
              <TextField source="merchantName" />
            </ReferenceField>
          }
          secondaryText={
            <ReferenceField source="id" reference="addresses" label="Country">
              <TextField source="countryName" />
            </ReferenceField>
          }
          tertiaryText={(record) => record.outletId}
          linkType="show"
        />
      ) : (
        <Datagrid
          isRowSelectable={(record: RaRecord | undefined) =>
            !record?.extOutletId
          }
          rowStyle={(record: RaRecord) => outletRowStyle(record, themeMode)}
        >
          <TextField
            source="extOutletId"
            label="External Outlet Id"
            emptyText={notApplicable}
          />
          <TextField source="outletId" label="Outlet Id" />
          <TextField source="name" label="Outlet Name" />
          <ReferenceField
            source="merchantId"
            reference="merchants"
            label="Company Id"
            sortable={false}
            link={false}
          >
            <TextField source="companyId" />
          </ReferenceField>
          <ReferenceField
            source="merchantId"
            reference="merchants"
            label="Legal Name"
            sortable={false}
          >
            <TextField source="merchantName" />
          </ReferenceField>
          <DateField source="updatedAt" locales="sv-SE" />
          <BooleanField source="isMainOutlet" label="Is Main Outlet" />
          <BooleanField source="isActive" label="Is Active" />
          <BooleanField source="isApplicant" label="Is Applicant" />
          <BooleanField source="bpId" label="Bp Id" looseValue />
          <TextField source="vat" />
          <TextField source="type" label="Type" sortable={false} />
          <TextField source="address.countryName" label="Country" />
          <TextField source="address.city" label="City" />
          <TextField source="workshopType" label="workshopType" />
        </Datagrid>
      )}
    </List>
  );
};
