import { Box, Typography } from '@mui/material';
import {
  TextInput,
  BooleanInput,
  ReferenceInput,
  AutocompleteInput,
  RaRecord,
  SelectInput,
} from 'react-admin';
import { nameLengthValidators, requiredValidator } from 'utils/validators';
import { MERCHANT_TYPES } from '../../config/resources.config';

const optionRenderer = (choice: RaRecord) => {
  if (choice?.merchantName) {
    return `${choice?.companyId} - ${choice?.merchantName}`;
  }

  return `${choice?.companyId}`;
};

export const OutletStep = () => (
  <Box data-testid="outlet-step">
    <Box display="flex">
      <SelectInput
        inputProps={{ 'data-testid': 'type-input' }}
        source="workshopType"
        choices={MERCHANT_TYPES}
      />
    </Box>
    <TextInput
      inputProps={{ 'data-testid': 'name-input' }}
      source="name"
      label="Outlet"
      validate={[requiredValidator, ...nameLengthValidators]}
    />
    <Box display="flex" width={220}>
      <ReferenceInput
        emptyText="="
        label="Investor"
        reference="merchants"
        source="merchantId"
      >
        <AutocompleteInput
          validate={requiredValidator}
          label="Partner"
          optionText={optionRenderer}
          fullWidth
        />
      </ReferenceInput>
    </Box>
    <Box display="flex">
      <TextInput source="cofico" format={(cofico) => cofico ?? ''} />
    </Box>
    <Box display="flex">
      <TextInput source="brandCode" format={(brandCode) => brandCode ?? ''} />
    </Box>
    <Box display="flex">
      <BooleanInput source="isMainOutlet" />
      <BooleanInput source="isApplicant" />
    </Box>
    <Typography variant="body1" gutterBottom>
      To activate the outlet, you will need to provide an outlet name, VAT
      number, and a bpId.
    </Typography>
    <BooleanInput
      defaultValue
      source="isActive"
      data-testid="isActive-checkbox"
    />
    <TextInput
      inputProps={{ 'data-testid': 'vat-input' }}
      source="vat"
      format={(vat) => vat ?? ''}
      validate={requiredValidator}
    />
  </Box>
);
