// core imports
import {
  ReferenceField,
  BooleanField,
  Tab,
  FunctionField,
  RaRecord,
  DateField,
} from 'react-admin';

// component imports
import { TextField } from 'components/text-field/TextField';

import { Grid } from '@mui/material';

export const OutletDetailsShow = () => (
  <Grid container spacing={2}>
    <Grid item xs={3}>
      <Tab label="Summary">
        <TextField source="outletId" label="Outlet Id" />
        <TextField source="name" label="Outlet Name" />
        <ReferenceField
          source="merchantId"
          reference="merchants"
          label="Legal Name"
        >
          <FunctionField
            render={(record: RaRecord) => `${record.merchantName}`}
          />
        </ReferenceField>
        <ReferenceField
          source="merchantId"
          reference="merchants"
          label="Partner"
        >
          <FunctionField
            render={(record: RaRecord) => {
              if (record?.merchantName) {
                return `${record.companyId} - ${record.merchantName}`;
              }
              return `${record.companyId}`;
            }}
          />
        </ReferenceField>
        <TextField source="cofico" />
        <TextField source="brandCode" />
        <TextField source="type" />
        <TextField source="workshopType" />
      </Tab>
    </Grid>
    <Grid item xs={8}>
      <Tab label="Additional Info">
        <BooleanField source="isMainOutlet" />
        <BooleanField source="isActive" />
        <BooleanField source="isApplicant" />
        <TextField source="vat" />
        <TextField source="bpId" label="Bp Id" disabled />
        <DateField source="createdAt" label="Created At" locales="sv-SE" />
        <DateField source="updatedAt" label="Updated At" locales="sv-SE" />
      </Tab>
    </Grid>
  </Grid>
);
