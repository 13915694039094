import { useState } from 'react';
import {
  Stepper,
  Step,
  StepLabel,
  CircularProgress,
  Typography,
} from '@mui/material';
import {
  Button,
  SimpleForm,
  Toolbar,
  useCreate,
  useDelete,
  useNotify,
  useRedirect,
} from 'react-admin';
import { Create } from 'components/create/Create';
import { getErrorMessage } from 'utils';
import { LoqateAddress } from 'resources/types/loqate';
import { OutletStep } from './OutletStep';
import { ContactStep } from './ContactStep';
import { AddressStep } from './AddressStep';
import { ContractStep } from './ContractStep';

const STEPS = [
  'Outlet Details',
  'Address Details',
  'Contract Details',
  'Contact Details',
];

export const OutletCreateWizard = () => {
  const [activeStep, setActiveStep] = useState(0);
  const [isSkipped, setIsSkipped] = useState(false);
  const [fullAddressData, setFullAddressData] = useState<
    LoqateAddress | undefined
  >();

  const [create, { isLoading }] = useCreate();
  const [deleteOne] = useDelete();
  const redirect = useRedirect();
  const notify = useNotify();

  /**
   * Transform the data to include id, loqateId, domesticId, latitude and longitude
   */
  const addressTransform = (address: LoqateAddress, outletId: string) => {
    const transformedAddress = {};

    for (const key in address) {
      if (address[key]) {
        transformedAddress[key.charAt(0).toLowerCase() + key.slice(1)] =
          address[key];
      }
    }

    return {
      ...transformedAddress,
      id: outletId,
      loqateId: address?.Id,
      domesticId: address?.DomesticId,
      latitude: address?.Field1,
      longitude: address?.Field2,
    };
  };

  const createContact = async (
    formData: Record<string, any>,
    outletId: string
  ) => {
    await create(
      'contacts',
      {
        data: {
          id: outletId,
          phone: formData.phone,
          fax: formData.fax,
          email: formData.email,
        },
      },
      {
        onSuccess: () => {
          redirect(`/outlets`);
        },
        onError: (error) => {
          // Delete the outlet if the contact creation fails
          deleteOne('outlets', { id: outletId });
          const errorMsg = getErrorMessage(error);
          notify(errorMsg as string, {
            type: 'error',
          });
        },
      }
    );
  };

  const createContract = async (
    formData: Record<string, any>,
    outletId: string
  ) => {
    await create(
      'contracts',
      {
        data: {
          outletId,
          contractType: formData.contractType,
          contractName: formData.contractName,
          startDate: formData.startDate,
          endDate: formData.endDate,
          comment: formData.comment,
        },
      },
      {
        onSuccess: () => {
          createContact(formData, outletId);
        },
        onError: (error) => {
          // Delete the outlet if the contract creation fails
          deleteOne('outlets', { id: outletId });
          const errorMsg = getErrorMessage(error);
          notify(errorMsg as string, {
            type: 'error',
          });
        },
      }
    );
  };

  const createAddress = async (
    formData: Record<string, any>,
    outletId: string
  ) => {
    if (fullAddressData) {
      await create(
        'addresses',
        {
          data: { ...addressTransform(fullAddressData, outletId) },
        },
        {
          onSuccess: () => {
            if (isSkipped) {
              createContact(formData, outletId);
            } else {
              createContract(formData, outletId);
            }
          },
          onError: (error) => {
            // Delete the outlet if the address creation fails
            deleteOne('outlets', { id: outletId });
            const errorMsg = getErrorMessage(error);
            notify(errorMsg as string, {
              type: 'error',
            });
          },
        }
      );
    }
  };

  const createOutlet = async (formData: Record<string, any>) => {
    await create(
      'outlets',
      {
        data: {
          name: formData.name,
          merchantId: formData.merchantId,
          cofico: formData.cofico,
          brandCode: formData.brandCode,
          isMainOutlet: formData.isMainOutlet,
          isActive: formData.isActive,
          isApplicant: formData.isApplicant,
          vat: formData.vat,
          workshopType: formData.workshopType,
        },
      },
      {
        onSuccess: (outletData) => {
          createAddress(formData, outletData.id);
        },
        onError: (error) => {
          const errorMsg = getErrorMessage(error);
          notify(errorMsg as string, {
            type: 'error',
          });
        },
      }
    );
  };

  const handleSubmit = async (formData: Record<string, any>) => {
    if (activeStep !== STEPS.length - 1) {
      if (formData.isActive && activeStep === 1 && !fullAddressData) {
        notify('Please provide an address for an active outlet', {
          type: 'error',
        });
        return;
      }
      setActiveStep((prevActiveStep) => prevActiveStep + 1);

      if (isSkipped && activeStep === 2) {
        setIsSkipped(false);
      }
      return;
    }

    try {
      await createOutlet(formData);
    } catch (error) {
      notify('Error while creating outlet/address/contact ', {
        type: 'error',
      });
    }
  };

  const getStepContent = (stepIndex: number) => {
    switch (stepIndex) {
      case 0:
        return <OutletStep />;
      case 1:
        return (
          <AddressStep
            fullAddressData={fullAddressData}
            setFullAddressData={setFullAddressData}
          />
        );
      case 2:
        return <ContractStep />;
      case 3:
        return <ContactStep />;
      default:
        return 'Unknown step';
    }
  };

  const handleBack = () => {
    setActiveStep((prevActiveStep) => prevActiveStep - 1);
  };

  const handleSkip = () => {
    setIsSkipped(true);
    setActiveStep((prevActiveStep) => prevActiveStep + 1);
  };

  const Optional = (index: number) =>
    index === 2 ? <Typography variant="caption">Optional</Typography> : null;

  return (
    <>
      <Stepper
        activeStep={activeStep}
        alternativeLabel
        sx={{ marginTop: '10px' }}
      >
        {STEPS.map((label, index) => {
          const stepProps: { completed?: boolean } = {};

          if (isSkipped && index === 2) {
            stepProps.completed = false;
          }
          return (
            <Step key={label} {...stepProps}>
              <StepLabel optional={Optional(index)}>{label}</StepLabel>
            </Step>
          );
        })}
      </Stepper>
      <Create>
        <SimpleForm
          onSubmit={handleSubmit}
          mode="all"
          reValidateMode="onChange"
          toolbar={
            <Toolbar sx={{ display: 'flex', gap: '10px' }}>
              <Button
                data-testid="back-button"
                label="Back"
                disabled={activeStep === 0 || isLoading}
                onClick={handleBack}
              />
              {activeStep === 2 && (
                <Button
                  data-testid="skip-button"
                  label="Skip"
                  disabled={isLoading}
                  onClick={handleSkip}
                />
              )}
              <Button
                data-testid="next-button"
                type="submit"
                variant="contained"
                label={activeStep === STEPS.length - 1 ? 'Save' : 'Next'}
                disabled={isLoading}
              />
            </Toolbar>
          }
        >
          {isLoading && <CircularProgress sx={{ margin: 'auto' }} />}
          {getStepContent(activeStep)}
        </SimpleForm>
      </Create>
    </>
  );
};
